:root {
  --primary: #242424;
  --text: #09871f;
}

.btn {
  padding: 12px 24px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  font-family: 'Bebas Neue', cursive;
  transition: all 0.3s ease-out;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.btn--primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--inverted {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 2rem;
}

.btn--large {
  padding: 12px 26px;
  font-size: 2rem;
}

.btn--medium:hover, .btn--large:hover {
  background: var(--text);
  border: 1px solid var(--text);
  color: #fff;
  transition: all 0.3s ease-out;
}