
.scrollTop {
  color: #09871f;
  position: fixed; 
  width: 100%;
  height: 3em;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .7));
}

.showArrow {
  display: flex;
  animation: fadeIn 1s;
  transition: opacity 0.6s;
  opacity: 0.8;
}

.hideArrow {
  animation: fadeOut 0.8s;
  transition: opacity 0.6s;
  opacity: 0;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}