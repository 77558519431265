.navbar {
  position: fixed;
  top: 0;
  background: #fff;
  height: 85px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 999;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
}

.navbar-logo {
  cursor: pointer;
  display: flex;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width:45vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 85px;
}

.nav-links {
  color: #09871f;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: var(--fontTitle);
}

.nav-links:hover {
  color: #242424;
  border-bottom: 4px solid #242424;
  transition: all 0.15s ease-out;
}

.fa-bars {
  color: #242424;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.social-icons-navbar {
  display: none;
}

@media screen and (max-width: 960px) {
  
  .NavbarItems {
    position: absolute;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    color: #fff;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #09871f;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #242424;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 60%;
    text-decoration: none;
    font-size: 2rem;
    background-color: transparent;
    color: #fff;
    padding: 16px 20px;
    border: 1px solid #fff;
    font-family: 'Bebas Neue', cursive;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #09871f;
    transition: 250ms;
  }

  .social-icons-navbar {
    display: flex;
    justify-content: space-between;
    margin: 0 20%;
    }
}