.page-container {
  margin: 5rem 10rem 5rem;
  padding: 20px;
  font-family: var(--fontText)
}

.page-content__title {
  margin: 1rem auto 1.5rem;
  font-size: 2rem;
  font-weight: 800;
}

.page-content__subtitle {
  margin: 1.2rem auto 1rem;
  font-size: 1.6rem
}

.page-content__text {
  font-size: 1rem;
  line-height: 1.3rem;
}

.page-content__text > p {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 960px) {
  .page-container {
    margin: 6rem 2rem 2rem; 
    padding: 5px;
    align-self: center;
  }
}